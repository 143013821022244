@import '../theme-bootstrap';

// common tout styles
.tout {
  &__header {
    &--large {
      @if $typography_cr24 == true {
        @include heading-3xl;
      } @else {
        @include h1;
      }
      line-height: 1;
    }
    &--medium {
      @if $typography_cr24 == true {
        @include heading-2xl;
      } @else {
        @include h2;
      }
      line-height: 1;
    }
    &--small {
      @if $typography_cr24 == true {
        @include heading-xl;
      } @else {
        @include h3;
      }
      line-height: 1;
    }
  }
  &__copy {
    &--large {
      @if $typography_cr24 == true {
        @include body-xl;
      } @else {
        @include t1;
      }
    }
    &--small {
      @if $typography_cr24 == true {
        @include body-r;
      } @else {
        @include t2;
      }
    }
  }
  &__cta {
    margin: 20px 0 0 0;
  }
  p {
    padding: 0;
    margin: 0;
    line-height: inherit;
  }
  // landscape version
  &--landscape {
    @include pie-clearfix;
    position: relative;
    width: 100%;
    .tout__content {
      padding: 20px;
    }
    &.tout--bg-image {
      .tout__content {
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
